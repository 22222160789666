.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen {
  .str-chat-channel.messaging .str-chat__main-panel {
    padding: 0 !important; /* ここで好きな値に変更 */
  }
}

.str-chat__list {
  padding-bottom: 80px; /* これにより、メッセージ入力部分のスペースを確保 */
}
.str-chat__input-flat {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1152px; /* 必要に応じて調整 */
  background-color: #fff;
  z-index: 1000;
}


/* 
.str-chat-channel {
  height: calc(100vh - 136px) !important;
}

@media (min-width: 640px) {
  .str-chat-channel {
    height: calc(100vh - 156px) !important;
  }
} */

.str-chat-channel {
  height: 70vh !important;
}

@media (min-width: 640px) {
  .str-chat-channel {
    height: 87vh !important
  }
}

.str-chat__thread--full {
  background-color: #61dafb;
  border-left: 1px solid #ddd;
}


.unread-message {
  background-color: #e0f7fa; /* 背景を少し青みがかった色に */
  border-left: 4px solid #00796b; /* 左側に目立つボーダー */
  padding-left: 8px; /* ボーダーとの余白 */
}
