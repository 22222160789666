/* CelebrationAnimation.css */
.dark-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明の黒 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  display: none; /* デフォルトでは非表示 */
}

.dark-background.active {
  display: flex; /* アクティブな時は表示 */
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  position: relative; /* これを追加 */
}

.modal-content {
  color: #ff69b4; /* ポップなピンク色 */
  font-family: 'Comic Sans MS', 'Comic Neue', cursive; /* ポップなフォント */
  font-size: 2rem;
}

.close-button {
  background: rgb(113, 102, 102);
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 25px; /* ボタンの角を丸く */
}
